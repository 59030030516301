import React from 'react'
import { Link } from 'gatsby'
import './PageTabSwitch.scss'

export default ({ links }) => {
	return (
		<nav className={'PageTabSwitch'}>
			<ul className={'PageTabSwitch__list'}>
				{links.map((link) => (
					<li key={link.name}>
						<Link
							className={'link'}
							activeClassName={'active'}
							to={link.to}
						>
							{link.name}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	)
}
