import React from 'react'
import './PaginationNavigation.scss'
import { Link } from 'gatsby'

export default (props) => {
	const { previousPagePath, nextPagePath } = props.pageContext
	return (
		<div className={'PaginationNavigation__row'}>
			{previousPagePath ? (
				<Link
					className="link__underline--primary"
					to={previousPagePath}
				>
					Vorige pagina
				</Link>
			) : null}
			{nextPagePath ? (
				<Link
					className={
						'PaginationNavigation__next link__underline--primary'
					}
					to={nextPagePath}
				>
					Volgende pagina
				</Link>
			) : null}
		</div>
	)
}
