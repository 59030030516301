import React from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import { graphql } from 'gatsby'

import PageTabSwitch from '../components/PageTabSwitch/PageTabSwitch'
import Title from '../components/Title/Title'
import PagePadding from '../components/PagePadding/PagePadding'
import PaginationNavigation from '../components/PaginationNavigation/PaginationNavigation'
import FeedCardHolderBig from '../components/FeedCardHolderBig/FeedCardHolderBig'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'

export default function News(props) {
	const allWordpressWpNieuws = props.data.allWordpressWpNieuws.edges

	const news = allWordpressWpNieuws
		.map((event) => {
			return {
				titles: [event.node.acf.title, undefined],
				textContent: [
					undefined,
					ReactHtmlParser(event.node.acf.description),
				],
				date: event.node.acf.date,
				image: event.node.acf.image,
			}
		})
		.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())

	return (
		<SecondaryLayout>
			<Helmet>
				<title>
					Nieuws | In Balans Alkmaar Schaats- en Skeelerschool
				</title>
				<meta
					name="description"
					content={'Blijf op de hoogte van In Balans Alkmaar.'}
				/>
			</Helmet>
			<PagePadding>
				<Column>
					<Title
						heading={1}
						text={'Nieuws van InBalans'}
						wave={true}
					/>
					<PageTabSwitch
						links={[
							{ name: 'Kalender', to: '/kalender' },
							{ name: 'Nieuws', to: props.uri },
							{ name: 'FAQ', to: '/faq' },
						]}
					/>
					<Column>
						<FeedCardHolderBig cards={news} />
					</Column>
				</Column>
				<PaginationNavigation pageContext={props.pageContext} />
			</PagePadding>
		</SecondaryLayout>
	)
}

export const pageQuery = graphql`
	query($skip: Int!, $limit: Int!) {
		allWordpressWpNieuws(
			sort: { fields: acf___date, order: DESC }
			skip: $skip
			limit: $limit
			filter: { acf: { show: { eq: true } } }
		) {
			edges {
				node {
					acf {
						date
						description
						title
						image {
							alt_text
							localFile {
								url
								childImageSharp {
									# Try editing the "maxWidth" value to generate resized images.
									fluid {
										...GatsbyImageSharpFluid_withWebp_tracedSVG
									}
								}
							}
						}
						show
					}
				}
			}
		}
	}
`
